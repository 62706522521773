import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import color, { fox } from 'styles/colors';
import { fadein } from 'styles/animations';
import { respondTo } from 'styles/helper';
import { Profile } from 'lib/types/profileType';
import { useFlowManager } from 'lib/hooks';
import RegCodeSchema from 'validations/schemas/RegCodeSchema';
import { AppContext } from 'context/GlobalContextProvider';
import { Button, Input } from 'components/common';
import { PATH_ACTIVATE } from 'constants/index';
import useRegCodeInput from './hooks';

type Props = {
  setRegCodeError: (b: boolean) => boolean | void;
  profile: Profile;
};

const RegCodeInput = ({ profile, setRegCodeError }: Props): JSX.Element => {
  const {
    state: { error },
  } = useContext(AppContext);
  const { handleSubmit: handleSubmitRegCode } = useRegCodeInput({
    profile,
    setRegCodeError,
  });

  const { isNation } = useFlowManager();
  const [qrCode, setQrCode] = useState<string>();
  const [isQrCode, setIsQrCode] = useState<boolean>(false);

  useEffect(() => {
    if (location?.pathname?.startsWith(PATH_ACTIVATE)) {
      const arr = location?.href.split('/').filter((str) => str);
      const code = arr[arr?.indexOf('activate') + 1];
      setQrCode(code);
      if (code) setIsQrCode(true);
    }
  }, []);

  type IFormInput = {
    regCode: string;
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<IFormInput>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: RegCodeSchema(),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    await handleSubmitRegCode(data.regCode.toUpperCase());
  };

  const disable = (): boolean => {
    if (isQrCode) {
      return false;
    }
    return !isValid;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('regCode', {
            required: true,
          })}
          placeholder="Activation Code"
          autoCapitalize="on"
          type="text"
          autoFocus
          className={error ? 'error' : ''}
          defaultValue={qrCode}
        />
        <Button kind={isNation ? 'secondary' : 'default'} type="submit" disabled={disable()}>
          Submit
        </Button>
      </Form>
    </>
  );
};

export default RegCodeInput;

const Form = styled.form`
  ${fadein};
  input {
    text-transform: uppercase;
    padding: 0;
    ${respondTo.bp_768`
      width: 200px;
  `}
    &:focus,
    :active {
      border: solid 2px ${fox.azureRadiance};
    }
    &::placeholder {
      color: ${color.white};
      text-transform: initial;
      opacity: 0.5;
    }
    &.error {
      border: solid 2px ${color.error};
    }
  }
`;
